<template>
  <!-- 学生成长回顾 -->
  <div class="bg bg4">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "../../../utils/axios";


export default {
  name: "reviewOfStudentGrowth",
  components: {ClassBut},
  data() {
    return {}
  },
  created() {
    this.getApiCourseStudentGrowUp()
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'PageSixth',
        query: this.$route.query,
      })
    },

    async getApiCourseStudentGrowUp(){
       let  res = await service.post('/dwart/an_teaching/hbk/v1/search/courseStudentGrowUp',{
         classId:this.$route.query.classId
       })
    },

  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
</style>
